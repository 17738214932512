import { Injectable } from '@angular/core';
import { DataRow } from '../pages/view/view.page';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

export interface AiSuggestion {
  dataIndex: number
  rule: AiRule
  approved: boolean
}

export interface AiRule {
  label: string
  query: string
  meta?: any
}

interface AiRuleContainer {
  delete: AiRule[]
  split: AiRule[]
  tax: AiRule[]
}

@Injectable({
  providedIn: 'root'
})
export class AiService {
  public rules_delete: AiRule[] = []
  public rules_split: AiRule[] = []
  public rules_tax: AiRule[] = []

  constructor(public auth: AuthService, public firestore: AngularFirestore) {
    auth.aiServiceReference = this;
   }

  suggesetDeletes(data: DataRow[]): AiSuggestion[] {
    let sug: AiSuggestion[] = []
    data.forEach((row, index) => {
      this.rules_delete.forEach(rule => {
        if (row.entry.note.toLowerCase().includes(rule.query.toLowerCase()) || row.entry.text.toLowerCase().includes(rule.query.toLowerCase())) {
          // approved sets whether the change is base approved or has to be checked manually 
          sug.push({ dataIndex: index, rule: rule, approved: true })
        }
      })
    });
    return sug;
  }

  suggesetSplits(data: DataRow[]): AiSuggestion[] {
    let sug: AiSuggestion[] = []
    data.forEach((row, index) => {
      this.rules_split.forEach(rule => {
        if (row.entry.note.toLowerCase().includes(rule.query.toLowerCase()) || row.entry.text.toLowerCase().includes(rule.query.toLowerCase())) {
          if ((row.entry.brutto > rule.meta.rent_split) && (rule.meta.rent_split > 0) ||
              (row.entry.brutto < rule.meta.rent_split) && (rule.meta.rent_split < 0)) {
            // approved sets whether the change is base approved or has to be checked manually
            sug.push({ dataIndex: index, rule: rule, approved: true })
          }
        }
      })
    });
    return sug;
  }

  suggesetTaxes(data: DataRow[]): AiSuggestion[] {
    let sug: AiSuggestion[] = []
    data.forEach((row, index) => {
      this.rules_tax.forEach(rule => {
        if (row.entry.note.toLowerCase().includes(rule.query.toLowerCase()) || row.entry.text.toLowerCase().includes(rule.query.toLowerCase())) {
          // approved sets whether the change is base approved or has to be checked manually 
          sug.push({ dataIndex: index, rule: rule, approved: true })
        }
      })
    });
    return sug;
  }

  public saveRules() {
    let data: AiRuleContainer = {
      delete: this.rules_delete,
      split: this.rules_split,
      tax: this.rules_tax
    }
    localStorage.setItem('rules', JSON.stringify(data));

    // sync rules to cloud if authenticated
    this.syncToCloud(JSON.stringify(data));
  }

  public loadRules() {
    let data = localStorage.getItem('rules');
    if (data) {
      let deserial: AiRuleContainer = JSON.parse(data);
      this.rules_delete = deserial.delete;
      this.rules_split = deserial.split;
      this.rules_tax = deserial.tax;
    } else {
      this.rules_delete = [];
      this.rules_split = [];
      this.rules_tax = [];
    }

    // sync rules from cloud if authenticated
    this.syncFromCloud();
  }

  private syncToCloud(rules) {
    console.log('cloud sync (write)');
    this.auth.authDetails().subscribe((user) => {
      this.firestore.doc(`users/${user.uid}`).set({
        rules
      });
    });
  }

  private syncFromCloud() {
    this.auth.authDetails().subscribe((user) => {
      // data
      this.firestore.collection(`users`).doc(`${user.uid}`).valueChanges().subscribe((userStorage) => {
        console.log(userStorage, 'DATABASE READ');

        if(userStorage) {
        let deserial: AiRuleContainer = JSON.parse((userStorage as any).rules);
        this.rules_delete = deserial.delete;
        this.rules_split = deserial.split;
        this.rules_tax = deserial.tax;  
        } else {
          console.log('could not find rules in cloud - syncing local rules to cloud');
          this.saveRules();
        }

      });
    });
  }
}
