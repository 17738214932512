import { Component, OnInit, enableProdMode } from '@angular/core';
import { AlertButton, AlertController, ModalController } from '@ionic/angular';
import { RuleTester } from 'eslint';
import { AddRulePage } from './pages/add-rule/add-rule.page';
import { AddPage } from './pages/add/add.page';
import { AiPage } from './pages/ai/ai.page';
import { ImportPage } from './pages/import/import.page';
import { AiRule, AiService } from './services/ai.service';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { MenuPage, MenuService } from './services/menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BkPage } from './pages/bk/bk.page';
import { BkImportPage } from './pages/bk/bk-import/bk-import.page';

declare var gtag

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, MenuPage {
  public uvaActions = [
    { title: 'Neu', cmd: 'new', icon: 'refresh' },
    { title: 'Importieren', cmd: 'import', icon: 'arrow-up' },
    { title: 'Regeln andwenden', cmd: 'ai', icon: 'sparkles' },
    { title: 'Hinzufügen', cmd: 'add', icon: 'add' },
    { title: 'Exportieren', cmd: 'export', icon: 'arrow-down' }
  ];

  public bkActions = [
    { title: 'Importieren', cmd: 'bk_import', icon: 'arrow-up' },
    { title: 'Exportieren', cmd: 'bk_export', icon: 'arrow-down' }
  ];

  public paneStyle: string = '';

  public activeView: string = 'uva';

  print = false;

  constructor(
    public menuService: MenuService,
    public modalController: ModalController,
    public dataService: DataService,
    public alertController: AlertController,
    public aiService: AiService,
    public auth: AuthService,
    public route: ActivatedRoute) { }

  toggleMenu(): boolean {
    if (this.paneStyle == '') {
      this.paneStyle = '--side-width:0px';
      return false;
    } else {
      this.paneStyle = '';
      return true;
    }
  }

  ngOnInit() {
    this.menuService.setMenuPage(this);
    if (window.location.pathname != '/') {
      this.activeView = window.location.pathname.replace('/', '');
    }
    this.checkAnalyticsConsent();
    this.checkPrintStatus();
    this.disableScrollNumberInput();
  }

  disableScrollNumberInput() {
    document.addEventListener("wheel", function (event) {
      if ((document.activeElement as any).type === "number") {
        (document.activeElement as any).blur();
      }
    });
  }

  checkPrintStatus() {
    this.route.queryParams.subscribe(params => {
      this.print = params.print ? true : false;
      console.log(this.print, 'PRINT STATUS');
    });
  }

  async checkAnalyticsConsent() {
    let consent = localStorage.getItem("consentGranted");
    if (consent == null) {
      const alert = await this.alertController.create({
        header: 'Tracking Consent',
        message: 'Diese Seite verwendet <strong>Google Analytics</strong>. Stimmen Sie dem Tracking zu?',
        buttons: [{
          text: 'Ablehnen',
          cssClass: 'warning',
          handler: () => {
            localStorage.setItem("consentGranted", "false");
          }
        }, {
          text: 'Mehr Infos',
          handler: () => {
            window.open("https://support.google.com/analytics/answer/11593727", "_blank");
          }
        }, {
          text: 'Zustimmen',
          handler: () => {
            localStorage.setItem("consentGranted", "true");
            console.log('tracking consent provided - starting tracking');
            this.constentGranted();
          }
        }]
      });
      await alert.present();
    } else if (consent == 'true') {
      console.log('tracking consent previously provided - continuing tracking');
      this.constentGranted();
    }
  }

  constentGranted() {
    gtag('consent', 'update', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted'
    });
  }

  command(cmd: string) {
    console.log(cmd)
    if (cmd == 'import') {
      this.presentImportModal();
    } else if (cmd == 'export') {
      this.dataService.downloadData();
    } else if (cmd == 'add') {
      this.presentAddModal();
    } else if (cmd == 'new') {
      this.presentNewAlert();
    } else if (cmd == 'ai') {
      this.presentAiModal();
    }

    // todo extend for BK commands
    else if (cmd == 'bk_import') {
      this.presentBkImportModal();
    }
    else if (cmd == 'bk_export') {
      this.dataService.downloadBk();
    }
  }

  async presentImportModal() {
    const modal = await this.modalController.create({
      component: ImportPage,
      cssClass: 'import-modal'
    });
    return await modal.present();
  }

  async presentBkImportModal() {
    const modal = await this.modalController.create({
      component: BkImportPage,
      cssClass: 'import-modal'
    });
    return await modal.present();
  }

  async presentAddModal() {
    const modal = await this.modalController.create({
      component: AddPage,
      cssClass: 'add-modal'
    });
    return await modal.present();
  }

  async presentNewAlert() {
    let alertButtons: AlertButton[] = [
      {
        text: 'Zurück',
        role: 'cancel',
        cssClass: 'secondary'
      }, {
        text: 'Alles Löschen',
        cssClass: 'warning',
        handler: () => {
          this.dataService.clearWip();
        }
      }
    ];

    let lastImportAvailable = localStorage.getItem('lastimport');
    let lastImportButton: AlertButton = {
      text: 'Importstand laden',
      cssClass: 'warning',
      handler: () => {
        this.dataService.clearWip();
        this.dataService.loadLastImport();
      }
    };

    if (lastImportAvailable) {
      alertButtons.push(lastImportButton);
    }

    const alert = await this.alertController.create({
      header: 'Achtung!',
      message: 'Wenn Sie neu anfangen wird ihr gesamter bisheriger Datenstand <strong>unwiederuflich gelöscht</strong>.',
      buttons: alertButtons
    });
    await alert.present();
  }

  async presentAiModal() {
    const modal = await this.modalController.create({
      component: AiPage,
      cssClass: 'ai-modal'
    });
    return await modal.present();
  }

  async presentEditRuleModal(rule: AiRule, type: string, index: number) {
    const modal = await this.modalController.create({
      component: AddRulePage,
      cssClass: 'add-rule-modal',
      componentProps: {
        typeLocked: type,
        index: index,
        label: rule.label,
        query: rule.query,
        text1: rule.meta?.text1,
        text2: rule.meta?.text2,
        rent_split: rule.meta?.rent_split,
        tax: rule.meta?.tax,
      }
    });
    return await modal.present();
  }

  async presentAuthModal() {
    const alert = await this.alertController.create({
      header: 'Authentifizierung',
      inputs: [
        {
          name: 'email',
          type: 'text',
          placeholder: 'Email'
        },
        {
          name: 'password',
          type: 'password',
          placeholder: 'Passwort'
        }
      ],
      buttons: [
        {
          text: 'Zurück',
          role: 'cancel'
        }, {
          text: 'Regsitrieren',
          handler: (alertData) => {
            this.auth.createUser(alertData.email, alertData.password)
              .then(
                response => {
                  console.log(response, 'registered')
                }, error => {
                  console.error(error);
                  this.presentAuthErrorAlert();
                });
          }
        }, {
          text: 'Login',
          handler: (alertData) => {
            this.auth.signinUser(alertData.email, alertData.password)
              .then(
                response => {
                  console.log(response, 'logged in')
                }, error => {
                  console.error(error);
                  this.presentAuthErrorAlert();
                });
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAuthErrorAlert() {
    const alert = await this.alertController.create({
      header: 'Fehler',
      subHeader: 'bei der Authentifizierung.',
      message: 'Bitte stellen Sie sicher, dass Email und Passwort korrekt sind, und Sie ebenfalls entsprechend Registieren und Login gewählt haben.',
      buttons: ['Ok']
    });
    await alert.present();
  }

  async presentLogoutModal() {
    const alert = await this.alertController.create({
      header: 'Regel Synchronisation',
      subHeader: 'ist aktiv.',
      message: 'Wollen Sie sich ausloggen und die Synchronisation beenden?',
      buttons: [
        {
          text: 'Zurück',
          role: 'cancel'
        },
        {
          text: "Ausloggen",
          handler: () => {
            this.auth.signoutUser()
          }
        }
      ]
    });
    await alert.present();
  }
}
