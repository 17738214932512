import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AiRule, AiService } from 'src/app/services/ai.service';
import { AiPage } from '../ai/ai.page';

@Component({
  selector: 'app-add-rule',
  templateUrl: './add-rule.page.html',
  styleUrls: ['./add-rule.page.scss'],
})
export class AddRulePage implements AfterViewInit {
  segment: string = '';
  finished = false;

  index: number;
  typeLocked;

  // all
  label: string = '';
  query: string = '';

  // delete has no extra info

  // split
  text1: string = '';
  text2: string = '';
  rent_split: number;

  // tax
  tax: number;

  constructor(public modalController: ModalController, public aiService: AiService) { }

  segmentChanged(event) {
    this.segment = event.detail.value;
    this.update();
  }

  update() {
    this.finished = (this.label.length > 0) && (
      (this.segment == 'delete')
      ||
      (this.segment == 'split' && this.text1.length > 0 && this.text2.length > 0 && this.rent_split != 0)
      ||
      (this.segment == 'tax' && this.tax >= 0)
    );
  }

  addRule() {
    if (this.index >= 0) {
      // update rule at index
      if (this.segment == 'delete') {
        this.aiService.rules_delete[this.index] = { label: this.label, query: this.query };
      } else if (this.segment == 'split') {
        this.aiService.rules_split[this.index] = { label: this.label, query: this.query, meta: { rent_split: this.rent_split, text1: this.text1, text2: this.text2 } };
      } else if (this.segment == 'tax') {
        this.aiService.rules_tax[this.index] = { label: this.label, query: this.query, meta: { tax: this.tax } };
      }
    } else {
      // create new rule
      if (this.segment == 'delete') {
        this.aiService.rules_delete.push({ label: this.label, query: this.query });
      } else if (this.segment == 'split') {
        this.aiService.rules_split.push({ label: this.label, query: this.query, meta: { rent_split: this.rent_split, text1: this.text1, text2: this.text2 } });
      } else if (this.segment == 'tax') {
        this.aiService.rules_tax.push({ label: this.label, query: this.query, meta: { tax: this.tax } });
      }
    }
    this.aiService.saveRules();
    this.modalController.dismiss();
  }

  applyAndAddRule() {
    this.addRule();

    let type: number; // 0 delete, 1 split, 2 tax
    let rule: AiRule;

    if (this.segment == 'delete') {
      type = 0;
      rule = { label: this.label, query: this.query };
    } else if (this.segment == 'split') {
      type = 1;
      rule = { label: this.label, query: this.query, meta: { rent_split: this.rent_split, text1: this.text1, text2: this.text2 } };
    } else if (this.segment == 'tax') {
      type = 2;
      rule = { label: this.label, query: this.query, meta: { tax: this.tax } };
    }

    this.presentAiModalAndApply(type, rule);
  }

  async presentAiModalAndApply(type: number, rule: AiRule) {
    const modal = await this.modalController.create({
      component: AiPage,
      cssClass: 'hidden',
      componentProps: {
        activeIndex: type,
        singleRuleApplication: rule
      }
    });
    return await modal.present();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.index >= 0) {
        this.segment = this.typeLocked;
        this.update();
      }
    });
  }

  deleteRule() {
    if (this.segment == 'delete') {
      this.aiService.rules_delete.splice(this.index, 1);
    } else if (this.segment == 'split') {
      this.aiService.rules_split.splice(this.index, 1);
    } else if (this.segment == 'tax') {
      this.aiService.rules_tax.splice(this.index, 1);
    }
    this.aiService.saveRules();
    this.modalController.dismiss();
  }
}
