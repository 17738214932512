import { Injectable } from '@angular/core';
import { DataResult, DataRow } from '../pages/view/view.page';
import * as XLSX from 'xlsx-with-styles';
import { Owner } from '../pages/bk/bk.page';
import { AlertController } from '@ionic/angular';

export interface UvaDataReceiver {
  addRows: (rows: DataRow[]) => void
  deleteIndex: (index: number) => void
  overwriteIndex: (data: DataRow, index: number) => void
  getAllData: () => DataRow[]
  getResultData: () => DataResult
  getDataHeaders: () => string[]
  clearWip: () => void
}

export interface BkDataReceiver {
  getAllData: () => Owner
  owner: Owner
  dataChange: () => void
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public uvaReceiver: UvaDataReceiver
  public bkReceiver: BkDataReceiver

  constructor(public alertController: AlertController) { }

  public readFiles(files: FileList) {
    console.log(files)
    for (let i = 0; i < files.length; i++) {
      if (files[i].name.endsWith('.csv')) {
        this.readCSV(files[i]);
      } else if (files[i].name.endsWith('.xlsx')) {
        this.readXlsx(files[i]);
      } else {
        this.presentUnknownFileError(files[i]);
      }
    }
  }

  async presentReadError(file: File) {
    const alert = await this.alertController.create({
      header: 'Lesefehler!',
      message: `Die Datei <strong>${file.name}</strong> konnte nicht gelesen werden.`,
      buttons: ['okay']
    });
    await alert.present();
  }

  async presentUnknownFileError(file: File) {
    const alert = await this.alertController.create({
      header: 'Unbekanntes Dateiformat!',
      message: `Die Datei <strong>${file.name}</strong> konnte nicht gelesen werden.`,
      buttons: ['okay']
    });
    await alert.present();
  }

  readCSV(file) {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      try {
        this.parseCSV(fileReader.result as string, file.name);
      } catch (error) {
        this.presentReadError(file);
        console.error(error);
      }
    }
    fileReader.readAsText(file)
  }

  readXlsx(file) {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      try {
        /* read workbook */
        const bstr = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.parseXLSX(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      } catch (error) {
        this.presentReadError(file);
        console.error(error);
      }
    }
    fileReader.readAsBinaryString(file);
  }

  readGGDLBKs(files: FileList) {
    let fileReader = new FileReader();
    for (let i = 0; i < files.length; i++) {
      console.log(files[i]);
      fileReader.onload = (e) => {
        this.bkReceiver.owner = JSON.parse(fileReader.result as string);
        this.bkReceiver.dataChange();
      }
      fileReader.readAsText(files[i])
    }
  }

  parseXLSX(readResult) {
    console.log(readResult);
    let max = readResult.length-9; // last data row
    let current = 0; // current data row
    readResult.forEach(row => {
      // skip 0 (header) row and work up to last data row
      if (current > 0 && current < max) { 
        let data: DataRow[] = [];
        data.push({
          entry: {
            date: this.excelDateToJSDate(row[0]),
            note: row[1],
            text: row[2],
            brutto: row[3],
            st_percentage: row[4]*100,
            konto: row[8]
          }
        })
        console.log(data[0].entry);
        this.uvaReceiver.addRows(data);
      }
      current++;
    })
    this.saveLastImport();
  }

  excelDateToJSDate(serial: number): Date {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;
    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }

  parseCSV(text: string, filename: string) {
    let data: DataRow[] = [];
    let rows: string[] = text.split('\n');

    rows.forEach(r => {
      let cols: string[] = r.split(';');

      let date = new Date();
      let date_split: string[] = cols[0].split('.')
      date.setDate(+date_split[0])
      date.setMonth((+date_split[1]) - 1)
      date.setFullYear(+date_split[2])

      data.push({
        entry: {
          date: date,
          text: cols[1].split('\"').join(''),
          brutto: +(cols[3].replace(',', '.')),
          konto: filename,
          note: '',
          st_percentage: 0
        }
      });
    })
    this.uvaReceiver.addRows(data);
    this.saveLastImport();
  }

  public downloadData() {
    // todo either download csv or xlsx
    this.downloadXlsx();
    // this.downloadCsv();
  }

  private getCsv(xlsCompatible = false): string {
    let str = '';

    this.uvaReceiver.getDataHeaders().forEach((h, i) => {
      if (i > 0) { // exclude "checked" property from export
        str += h + ';';
      }
    });
    str += '\n';

    this.uvaReceiver.getAllData().forEach(r => {
      let str_row = '';

      str_row += xlsCompatible ? r.entry.date + ';' : r.entry.date.getUTCDate() + '.' + (r.entry.date.getUTCMonth() + 1) + '.' + r.entry.date.getUTCFullYear() + ';';
      str_row += r.entry.note + ';';
      str_row += r.entry.text + ';';
      str_row += xlsCompatible ? (r.entry.brutto + ';') : (r.entry.brutto + ';').replace('.', ',');
      str_row += xlsCompatible ? (r.entry.st_percentage / 100 + ';') : (r.entry.st_percentage / 100 + ';').replace('.', ',');
      str_row += xlsCompatible ? (r.calculated.netto + ';') : (r.calculated.netto + ';').replace('.', ',');
      str_row += xlsCompatible ? (r.calculated.st_value + ';') : (r.calculated.st_value + ';').replace('.', ',');
      str_row += r.calculated.vst_or_ust + ';';
      str_row += r.entry.konto + ';';

      // combine with other data rows
      str += str_row + '\n';
    });

    // summary rows
    let r = this.uvaReceiver.getResultData();
    let r_str = '';
    r_str += '\n\n';
    r_str += ';;brutto;netto;;Text;Konto;Betrag\n';
    r_str += 'Einnahmen;0%;' + r.brutto_in_0 + ';' + r.netto_in_0 + ';;Umsatzsteuer;3500;' + r.ust + '\n';
    r_str += 'Einnahmen;10%;' + r.brutto_in_10 + ';' + r.netto_in_10 + ';Nr 029;Vorsteuer;2500;' + r.vst + ';Nr 060\n';
    r_str += 'Einnahmen;20%;' + r.brutto_in_20 + ';' + r.netto_in_20 + ';Nr 022;MWSt-Zahllast;3520;' + r.MWSt_Zahllast + '\n';
    r_str += 'GESAMT;;' + r.brutto_in_total + ';' + r.netto_in_total + '\n\n';
    r_str += ';;Umsätze;' + r.tax_revenue + ';Nr 000'; // todo is this really just the taxed umsätze?
    str += xlsCompatible ? r_str : r_str.split('.').join(',');
    return str;
  }

  private downloadXlsx() {
    let csv = this.getCsv(true);
    let lines = csv.split('\n').length; // brutto einnahmen += -5

    // parse csv into a workbook object
    const wb = XLSX.read(csv, { type: "string", cellDates: true });
    // get the worksheet (default name "Sheet1")
    const ws = wb.Sheets.Sheet1;

    // widths
    ws['!cols'] = [{ width: 10 }, { width: 30 }, { width: 70 }, { width: 15 }, { width: 10 }, { width: 15 }, { width: 15 }, { width: 10 }, { width: 50 },]

    // netto formulae
    for (let i = 0; i < lines - 10; i++) {
      let line = 2 + i;
      let cell = 'F' + line;
      ws[cell].f = 'D' + line + '/(1+E' + line + ')';
    }

    // st in eur formulae
    for (let i = 0; i < lines - 10; i++) {
      let line = 2 + i;
      let cell = 'G' + line;
      ws[cell].f = 'F' + line + ' * E' + line;
    }

    // ust vst formulae
    for (let i = 0; i < lines - 10; i++) {
      let line = 2 + i;
      let cell = 'H' + line;
      ws[cell].f = 'IF(D' + line + '>0,"Ust",IF(D' + line + '<0,"Vst",""))';
    }


    // result formulae
    ws['C' + (lines - 5)].f = 'SUMIFS(D2:D' + (lines - 9) + ',E2:E' + (lines - 9) + ',B' + (lines - 5) + ',D2:D' + (lines - 9) + ',">0")' // brutto 0
    ws['C' + (lines - 4)].f = 'SUMIFS(D2:D' + (lines - 9) + ',E2:E' + (lines - 9) + ',B' + (lines - 4) + ',D2:D' + (lines - 9) + ',">0")' // brutto 10
    ws['C' + (lines - 3)].f = 'SUMIFS(D2:D' + (lines - 9) + ',E2:E' + (lines - 9) + ',B' + (lines - 3) + ',D2:D' + (lines - 9) + ',">0")' // brutto 20
    ws['C' + (lines - 2)].f = 'SUM(C' + (lines - 5) + ':C' + (lines - 3) + ')' // total

    ws['D' + (lines - 5)].f = 'SUMIFS(F2:F' + (lines - 9) + ',E2:E' + (lines - 9) + ',B' + (lines - 5) + ',F2:F' + (lines - 9) + ',">0")' // netto 0
    ws['D' + (lines - 4)].f = 'SUMIFS(F2:F' + (lines - 9) + ',E2:E' + (lines - 9) + ',B' + (lines - 4) + ',F2:F' + (lines - 9) + ',">0")' // netto 10
    ws['D' + (lines - 3)].f = 'SUMIFS(F2:F' + (lines - 9) + ',E2:E' + (lines - 9) + ',B' + (lines - 3) + ',F2:F' + (lines - 9) + ',">0")' // netto 20
    ws['D' + (lines - 2)].f = 'SUM(D' + (lines - 5) + ':D' + (lines - 3) + ')' // total

    ws['H' + (lines - 5)].f = 'SUMIF(G2:G' + (lines - 9) + ',">0",G2:G' + (lines - 9) + ')' // ust
    ws['H' + (lines - 4)].f = 'SUMIF(G2:G' + (lines - 9) + ',"<0",G2:G' + (lines - 9) + ')' // vst
    ws['H' + (lines - 3)].f = 'SUM(H' + (lines - 5) + ':H' + (lines - 4) + ')' // mwst zahll

    ws['D' + lines].f = 'SUM(D' + (lines - 4) + ':D' + (lines - 3) + ')' // umsaetze

    // currency formats
    this.XlsCurrencyColoumnFormat(ws, 'D', lines);
    this.XlsCurrencyColoumnFormat(ws, 'F', lines);
    this.XlsCurrencyColoumnFormat(ws, 'G', lines);

    for (let i = lines - 5; i <= lines - 2; i++) {
      // brutto & netto result
      ws['C' + i].z = '€ ###,###,##0.00';
      ws['D' + i].z = '€ ###,###,##0.00';
    }
    for (let i = lines - 5; i <= lines - 3; i++) {
      // ust, vst, mwst zahll. result
      ws['H' + i].z = '€ ###,###,##0.00';
    }
    ws['D' + (lines)].z = '€ ###,###,##0.00';

    // percentage formats
    for (let i = 0; i < lines - 10; i++) {
      // rows
      let cell = 'E' + (2 + i);
      ws[cell].z = '#0 %';
    }

    for (let i = lines - 5; i <= lines - 3; i++) {
      // result
      let cell = 'B' + i;
      ws[cell].z = '#0 %';
    }

    // date formats and fixes
    for (let i = 0; i < lines - 10; i++) {
      let cell = 'A' + (2 + i);
      ws[cell].z = 'dd.mm.yyyy';
    }

    // autofilter
    ws['!autofilter'] = { ref: '1:1' };

    // header style
    const headerStyle = {
      fill: {
        patternType: "solid", // none / solid
        fgColor: { rgb: "FFDCDCDC" }
      },
      font: {
        bold: true
      }
    };
    this.XlsApplyHeaderStyle(ws, headerStyle);

    // result code styles
    const resultCodeStyle = {
      font: {
        color: { rgb: "FFFF0000" },
        bold: true,
      }
    };
    ws['E' + (lines - 4)].s = resultCodeStyle;
    ws['I' + (lines - 4)].s = resultCodeStyle;
    ws['E' + (lines - 3)].s = resultCodeStyle;
    ws['E' + (lines - 0)].s = resultCodeStyle;

    // result code-field styles
    const resultCodeFieldStyle = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFFF00" }
      }
    };
    ws['D' + (lines - 4)].s = resultCodeFieldStyle;
    ws['H' + (lines - 4)].s = resultCodeFieldStyle;
    ws['D' + (lines - 3)].s = resultCodeFieldStyle;
    ws['D' + (lines - 0)].s = resultCodeFieldStyle;

    // download excel file
    XLSX.writeFile(wb, 'uva.xlsx');
  }

  private XlsApplyHeaderStyle(ws, style) {
    let cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    cols.forEach((c) => { ws[c + '1'].s = style; });
  }

  private XlsCurrencyColoumnFormat(ws: XLSX.WorkSheet, coloumn: string, lines: number) {
    for (let i = 0; i < lines - 10; i++) {
      let cell = coloumn + (2 + i);
      ws[cell].z = '€ ###,###,##0.00';
    }
  }

  // todo add CSV download option
  private downloadCsv() {
    var link = document.createElement('a');
    link.download = 'uva.csv';
    var blob = new Blob([this.getCsv()], { type: 'text/plain' });
    link.href = window.URL.createObjectURL(blob);
    link.click();
  }

  public saveWip() {
    // clone data
    let data = this.uvaReceiver.getAllData();
    let serial: any = JSON.parse(JSON.stringify(data));

    serial.forEach((row, index) => {
      // serialize date manually
      row.entry.date = data[index].entry.date.toISOString();
    })
    localStorage.setItem('wip', JSON.stringify(serial));
  }

  public saveBkWip() {
    // clone data
    let data = this.bkReceiver.getAllData();
    let serial: any = JSON.parse(JSON.stringify(data));
    localStorage.setItem('bkwip', JSON.stringify(serial));
  }

  public saveLastImport() {
    // clone data
    let data = this.uvaReceiver.getAllData();
    let serial: any = JSON.parse(JSON.stringify(data));

    serial.forEach((row, index) => {
      // serialize date manually
      row.entry.date = data[index].entry.date.toISOString();
    })
    localStorage.setItem('lastimport', JSON.stringify(serial));
  }

  public loadLastImport() {
    let lastImportData: DataRow[] = null;
    let data = localStorage.getItem('lastimport');
    if (data) {
      let deserial: any[] = JSON.parse(data);
      deserial.forEach(row => {
        row.entry.date = new Date(row.entry.date);
      })
      lastImportData = deserial as DataRow[];
    } else {
      lastImportData = [];
    }
    this.uvaReceiver.addRows(lastImportData);
  }

  public loadWip(): DataRow[] {
    let data = localStorage.getItem('wip');
    if (data) {
      let deserial: any[] = JSON.parse(data);
      deserial.forEach(row => {
        row.entry.date = new Date(row.entry.date);
      })
      return deserial as DataRow[];
    } else {
      return [];
    }
  }

  public loadBkWip(): Owner {
    let data = localStorage.getItem('bkwip');
    if (data) {
      let deserial = JSON.parse(data);
      return deserial as Owner;
    } else {
      return;
    }
  }

  public clearWip() {
    localStorage.setItem('wip', JSON.stringify([]));
    this.uvaReceiver.clearWip();
  }

  public copyBkToClipboard() {
    navigator.clipboard.writeText(JSON.stringify(this.bkReceiver.getAllData()));
    alert("Werte in die Zwischenablage kopiert");
  }

  public downloadBk() {
    const blob = new Blob([JSON.stringify(this.bkReceiver.getAllData())], { type: 'text' });
    // let file = new File([JSON.stringify(this.bkReceiver.getAllData())], 'BK-Abrechnung ' + (new Date()).getFullYear(), { type: "application/json" });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'BK-Abrechnung ' + ((new Date()).getFullYear() - 1) + '-' + (new Date()).getFullYear() + '.ggdlbk';
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }
}
