import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AiService } from './ai.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public aiServiceReference = null;
  
  constructor(private angularFireAuth: AngularFireAuth) {}

  createUser(email, password) {
    return new Promise<any>((resolve, reject) => {
      this.angularFireAuth.createUserWithEmailAndPassword(email, password)
        .then(
          res => {
            resolve(res);
            this.aiServiceReference.loadRules();
          },
          err => reject(err))
    })
  }

  signinUser(email, password) {
    return new Promise<any>((resolve, reject) => {
      this.angularFireAuth.signInWithEmailAndPassword(email, password)
        .then(
          res => {
            resolve(res);
            this.aiServiceReference.loadRules();
          },
          err => reject(err))
    })
  }

  signoutUser() {
    return new Promise<void>((resolve, reject) => {
      if (this.angularFireAuth.currentUser) {
        this.angularFireAuth.signOut()
          .then(() => {
            console.log("Sign out");
            resolve();
          }).catch(() => {
            reject();
          });
      }
    })
  }

  authDetails() {
    return this.angularFireAuth.authState
  }
}
