import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'uva',
    pathMatch: 'full'
  },
  {
    path: 'uva',
    loadChildren: () => import('./pages/view/view.module').then( m => m.ViewPageModule)
  },
  {
    path: 'import',
    loadChildren: () => import('./pages/import/import.module').then( m => m.ImportPageModule)
  },
  {
    path: 'add',
    loadChildren: () => import('./pages/add/add.module').then( m => m.AddPageModule)
  },
  {
    path: 'ai',
    loadChildren: () => import('./pages/ai/ai.module').then( m => m.AiPageModule)
  },
  {
    path: 'add-rule',
    loadChildren: () => import('./pages/add-rule/add-rule.module').then( m => m.AddRulePageModule)
  },
  {
    path: 'bk',
    loadChildren: () => import('./pages/bk/bk.module').then( m => m.BkPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
