// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyA3yhc1Di7w-9xCtMhfiGO7mrDsGGv8jZk",
    authDomain: "ggdl-691da.firebaseapp.com",
    databaseURL: "https://ggdl-691da-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ggdl-691da",
    storageBucket: "ggdl-691da.appspot.com",
    messagingSenderId: "559445749692",
    appId: "1:559445749692:web:24de489ec97bd72b9985b9"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
