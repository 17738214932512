import { Component, HostListener, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.page.html',
  styleUrls: ['./add.page.scss'],
})
export class AddPage {
  date = '';
  text = '';
  brutto = '-';
  st_percentage = '20';
  note = 'Verbrauch';
  konto = 'Bar';

  index = undefined;

  constructor(public modalController: ModalController, public loadingController: LoadingController, public dataSerivce: DataService) { }

  add() {
    let dateD = new Date();

    let split: string[] = this.date.split('.');
    dateD.setDate(+split[0]);
    dateD.setMonth((+split[1]) - 1);
    dateD.setFullYear(+split[2]);

    let newData = {
      entry: {
        date: dateD,
        text: this.text,
        brutto: +this.brutto,
        st_percentage: +this.st_percentage,
        note: this.note,
        konto: this.konto
      }
    };

    if (this.index == undefined) {
      this.dataSerivce.uvaReceiver.addRows([newData]);
    } else {
      this.dataSerivce.uvaReceiver.overwriteIndex(newData, this.index);
    }

    this.index = undefined;
    this.modalController.dismiss();
  }

  delete() {
    this.dataSerivce.uvaReceiver.deleteIndex(this.index);
    this.modalController.dismiss();
  }
}
