import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AiRule, AiService, AiSuggestion } from 'src/app/services/ai.service';
import { DataService } from 'src/app/services/data.service';
import { DataRow } from '../view/view.page';

@Component({
  selector: 'app-ai',
  templateUrl: './ai.page.html',
  styleUrls: ['./ai.page.scss'],
})
export class AiPage implements OnInit {
  slideOpts = {
    initialSlide: 0,
    speed: 400,
    allowTouchMove: false
  };
  activeIndex = 0;
  slideHeaders: string[] = ['Löschung', 'Teilung', 'Steuern'];

  data: DataRow[] = [];

  sugDeletes: AiSuggestion[] = [];
  sugSplits: AiSuggestion[] = [];
  sugTaxes: AiSuggestion[] = [];

  sugSplitsSummary = {};

  singleRuleApplication: AiRule;

  constructor(
    public modalController: ModalController,
    public aiService: AiService,
    public dataService: DataService) { }

  ngOnInit() {
    this.data = this.dataService.uvaReceiver.getAllData();

    if (!this.singleRuleApplication) {
      this.sugDeletes = this.aiService.suggesetDeletes(this.data);
      this.sugSplits = this.aiService.suggesetSplits(this.data);
      this.sugTaxes = this.aiService.suggesetTaxes(this.data);

      // split summary
      this.sugSplitsSummary = {}
      this.sugSplits.forEach((s) => {
        if (this.sugSplitsSummary[s.rule.label]) {
          this.sugSplitsSummary[s.rule.label]++;
        } else {
          this.sugSplitsSummary[s.rule.label] = 1;
        }
      });
      console.log('summary');
      console.log(this.sugSplitsSummary);
    } else {
      if (this.activeIndex == 0) {
        let buffer = this.aiService.rules_delete;
        this.aiService.rules_delete = [this.singleRuleApplication];
        this.sugDeletes = this.aiService.suggesetDeletes(this.data);
        this.aiService.rules_delete = buffer;
      } else if (this.activeIndex == 1) {
        let buffer = this.aiService.rules_split;
        this.aiService.rules_split = [this.singleRuleApplication];
        this.sugSplits = this.aiService.suggesetSplits(this.data);
        this.aiService.rules_split = buffer;
      } else if (this.activeIndex == 2) {
        let buffer = this.aiService.rules_tax;
        this.aiService.rules_tax = [this.singleRuleApplication];
        this.sugTaxes = this.aiService.suggesetTaxes(this.data);
        this.aiService.rules_tax = buffer;
      }
      this.apply(null);
    }
  }

  updateNav(event) {
    this.activeIndex = event.target.swiper.activeIndex;
  }

  apply(slides) {
    let copy: DataRow[] = this.data.filter(r => true);
    if (this.activeIndex == 0) {
      // delete
      let delCounter = 0;
      this.sugDeletes.forEach(s => {
        if (s.approved) {
          this.data.forEach((row, index) => {
            if (index == s.dataIndex) {
              copy.splice(index - delCounter, 1);
              delCounter++;
            }
          });
        }
      })
    } else if (this.activeIndex == 1) {
      // split
      this.sugSplits.forEach(s => {
        if (s.approved) {
          this.data.forEach((row, index) => {
            if (index == s.dataIndex) {
              // split part 1
              copy[index].entry.brutto -= s.rule.meta.rent_split;
              copy[index].entry.note = s.rule.meta.text2;

              // split part 2
              let new_split = this.deepCopy([copy[index]])[0];
              new_split.entry.brutto = s.rule.meta.rent_split;
              new_split.entry.note = s.rule.meta.text1;
              copy.push(new_split);
            }
          });
        }
      })
    } else if (this.activeIndex == 2) {
      // tax
      this.sugTaxes.forEach(s => {
        if (s.approved) {
          this.data.forEach((row, index) => {
            if (index == s.dataIndex) {
              // split part 1
              copy[index].entry.st_percentage = s.rule.meta.tax;
              copy[index].entry.note = copy[index].entry.note.length == 0 ? s.rule.label :  s.rule.label + ': ' + copy[index].entry.note;
            }
          });
        }
      })
    }

    // refresh main data view
    this.dataService.uvaReceiver.clearWip();
    this.dataService.uvaReceiver.addRows(copy);

    if (!this.singleRuleApplication) {
      slides.slideNext();
      if (this.activeIndex == 2) {
        this.modalController.dismiss();
      }
    } else {
      this.modalController.dismiss();
    }

    // reload to have fresh data on next ai step
    this.singleRuleApplication = undefined;
    this.ngOnInit();
  }

  deepCopy(data: DataRow[]): DataRow[] {
    let serial: any = JSON.parse(JSON.stringify(data));
    serial.forEach((row, index) => {
      // serialize date manually
      row.entry.date = data[index].entry.date.toISOString();
    });
    serial = JSON.stringify(serial);

    let deserial: any = JSON.parse(serial);
    deserial.forEach(row => {
      row.entry.date = new Date(row.entry.date);
    });
    return deserial as DataRow[];
  }

  skip(slides) {
    slides.slideNext();
  }
}
