import { Injectable } from '@angular/core';

export interface MenuPage {
  toggleMenu: () => boolean
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  page: MenuPage = null;
  open: boolean = true;

  constructor() { }

  public setMenuPage(page) {
    this.page = page;
  }

  public toggle(): boolean {
    return this.page.toggleMenu();
  }
}
